<template>
    <aside class="i18n-switch">
        <span class="i18n-switch__toggle" @click="openOptions">{{ active }}</span>
        <div class="i18n-switch__options" v-if="open">
            <h3 class="i18n-switch__title">{{ title }}</h3>
            <span class="i18n-switch__close" @click="closeOptions" />
            <span
                class="i18n-switch__option"
                v-for="(option, key) in options"
                :key="key"
                @click="setLanguage(option.lng)"
                v-class-mod:i18n-switch__option="{ active: isActive(option.lng) }"
            >
                {{ option.label }}
            </span>
        </div>
        <span v-if="open" class="i18n-switch__close-overlay" @click="closeOptions" />
    </aside>
</template>

<script lang="ts">
import { defineComponent, computed, reactive } from 'vue';
import { i18n, translate, setLanguage, LANGUAGE } from '@/features/i18n/i18n-translate';

const labels: { [key in LANGUAGE]: string } = {
    [LANGUAGE.NO]: 'Norsk',
    [LANGUAGE.EN]: 'English',
};
let data = reactive({
    open: false,
    options: [
        { lng: LANGUAGE.NO, label: labels[LANGUAGE.NO] },
        { lng: LANGUAGE.EN, label: labels[LANGUAGE.EN] },
    ],
});

export default defineComponent({
    data: () => data,
    setup: () => ({
        title: computed(() => translate('app', 'i18n', 'title')),
        active: computed(() => labels[i18n.locale as LANGUAGE]),
        openOptions: () => (data.open = true),
        closeOptions: () => (data.open = false),
        setLanguage: (lng: LANGUAGE) => {
            setLanguage(lng);
            data.open = false;
        },
        isActive: (lng: LANGUAGE) => {
            return i18n.locale === lng;
        },
    }),
});
</script>

<style lang="scss" scoped>
.i18n-switch {
    position: relative;
    &__toggle {
        z-index: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        padding-right: 0.625rem;
        position: relative;
        &:before {
            font-size: 1.875rem;
            @include icon('keyboard_arrow_down');
            margin-right: 0.3125rem;
        }
        &:hover:after {
            opacity: 1;
        }
    }
    &__options {
        width: 15rem;
        max-width: calc(100vw - 1.25rem);
        position: absolute;
        z-index: 9;
        right: 0;
        top: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding: 0.625rem;
        border-radius: 0.625rem;
        background: var(--sand);
        box-shadow: 0px 0px 10px var(--sand);
        @include breakpoint(medium) {
            padding: 1.25rem;
        }
    }
    &__title {
        font-size: 0.875rem;
        text-align: center;
        font-weight: 600;
        line-height: 2.5rem;
        width: 100%;
    }
    &__option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.625rem 0;
        margin: 0 0.625rem;
        cursor: pointer;
        &--active {
            border-bottom: 2px solid;
        }
    }
    &__close {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        font-size: 1.875rem;
        height: 2.5rem;
        width: 2.5rem;
        z-index: 9;
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
        cursor: pointer;
        @include breakpoint(medium) {
            top: 1.25rem;
            right: 1.25rem;
        }
        &:before {
            @include icon('close');
        }
        &:after {
            z-index: -1;
            content: '';
            @include position-corner;
            position: absolute;
            background: var(--sand);
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        &:hover:after {
            opacity: 1;
        }
    }
    &__close-overlay {
        position: fixed;
        background: var(--secondary);
        opacity: 0.2;
        z-index: 8;
        @include position-corner;
    }
    &__compliance {
        display: flex;
        flex-flow: column;
        font-size: 0.875rem;
        line-height: 1.5;
        margin-top: 1.25rem;
    }
    &__compliance-turn-on {
        justify-content: center;
        cursor: pointer;
        user-select: none;
        border-radius: 0.3125rem;
        padding: 0.625rem 0.3125rem;
        background: var(--ocean);
        color: var(--white);
        text-align: center;
        margin-top: 0.625rem;
    }
}
</style>
