
import { defineComponent, computed, reactive } from 'vue';
import { i18n, translate, setLanguage, LANGUAGE } from '@/features/i18n/i18n-translate';

const labels: { [key in LANGUAGE]: string } = {
    [LANGUAGE.NO]: 'Norsk',
    [LANGUAGE.EN]: 'English',
};
let data = reactive({
    open: false,
    options: [
        { lng: LANGUAGE.NO, label: labels[LANGUAGE.NO] },
        { lng: LANGUAGE.EN, label: labels[LANGUAGE.EN] },
    ],
});

export default defineComponent({
    data: () => data,
    setup: () => ({
        title: computed(() => translate('app', 'i18n', 'title')),
        active: computed(() => labels[i18n.locale as LANGUAGE]),
        openOptions: () => (data.open = true),
        closeOptions: () => (data.open = false),
        setLanguage: (lng: LANGUAGE) => {
            setLanguage(lng);
            data.open = false;
        },
        isActive: (lng: LANGUAGE) => {
            return i18n.locale === lng;
        },
    }),
});
